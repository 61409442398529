<template>
    <div>
        <div class="playbox" v-if="showplay">
            <div class="playcont">
                <video :width="videowidth" style="border-radius: 5px;" autoplay controls :src="playurl"></video>
                <span @click="closeme">
                    <i class="el-icon-close"></i>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {}
        },
        props: {
            showplay: {
                type: Boolean,
                default: false
            },
            playurl: {
                type: String,
                default: ""
            },
            videowidth: {
                type: Number,
                default: 450
            }
        },
        methods: {
            closeme() {
                //this.showplay=false
                this.$emit("closeplay", false)
            }
        }
    }
</script>
<style scoped>
    .playbox {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        z-index: 2000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .playcont {
        width: auto;
        height: auto;
        border-radius: 5px;
        max-width: 500px;
        box-shadow: #333333 0 0 25px;
        position: relative;
    }

    .playcont span {
        position: absolute;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: #ffffff;
        top: 0;
        right: -45px;
        cursor: pointer;
    }

    .playcont span i {
        font-size: 30px;
    }
</style>