<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {}
    },
    // watch: {
    //   '$i18n.locale'(newVal, oldVal) {
    //     if (newVal !== oldVal) {
    //       this.reload()
    //     }
    //   },
    // },
  }
</script>

<style>
  html,
  body {
    width: 100%;
    height: 100%;
  }
  #app {
    height: 100%;
  }
</style>