<template>
    <div class="layout" style="height: 100%;">
        <el-container style="height: 100%;">
            <el-header style="z-index:2000;"><Head :logourl="logo" @getchange="changeiscoll"/></el-header>
            <el-container >
                
                
                <el-aside :width="sidewidth" style="box-shadow: rgb(236, 236, 236) 2px 2px 15px; z-index:1000;">
                    <Menu :activePath="activePath" :changmenu="isCollapse" :logourl="logo"  />
                </el-aside>
                <el-main style="padding:0 0px 20px 0px;">
                    <Viewtag />
                    <transition :name="transitionName" >  
                        <router-view style="padding:0 15px 15px 15px;"></router-view>
                    </transition>
                </el-main>
            </el-container>
        </el-container>

    </div>
</template>
<script>
import Menu from "../Menu.vue"
import Head from "../Head.vue"
import Viewtag from "../viewtag.vue"
export default { 
    data() {
        return{ 
            isCollapse:false,
            sidewidth:"200px",
            //logo:require("../../assets/images/logo1.png"),
            activePath:"",
            transitionName:'vux-pop-in'
        }
    },
    components:{
        Menu,
        Head,
        Viewtag
    },
    watch: {
        $route(to, from) {
            this.activePath = to.path
            if(to.meta.index > from.meta.index){
            //设置动画名称
                this.transitionName = 'slide-right';
            }else{
                this.transitionName = 'slide-right';
            }
        }
    },
    methods: {
        changeiscoll(data){
            this.isCollapse=data
            if(data){
                this.sidewidth="68px"
                //this.logo=require("../../assets/images/logo2.png")
            }else{
                this.sidewidth="200px"
                //this.logo=require("../../assets/images/logo1.png")
            }
        },
        
    }
}
</script>
<style>
   @import '../../assets/css/public.css';
el-aside {
    background-color: #0f080f;
    color: #333;
    text-align: center; 
    line-height: 200px;
  }
.el-header{ box-shadow: rgba(0,0,0,0.2) 10px 0px 15px;} 
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
 will-change: transform;
 transition: all 300ms;
 position: absolute;
 width: 87%;
}
.slide-right-enter {
 opacity: 0;
 transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
 opacity: 0;
 transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
 opacity: 0;
 transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
 opacity: 0;
 transform: translate3d(-100%, 0, 0);
}

</style>