import axios from 'axios'
import { Message } from 'element-ui'

let pedding = []
const CancelToken = axios.CancelToken
let whiteList = [ //无需token的接口数组
    '/auth/login',
    '/auth/code',
    '/auth/logout',
    ]
//let token = JSON.parse(window.sessionStorage.getItem('token'))
//console.log(7,token)
//let token = null
//创建axios实例
const service = axios.create({
    baseURL: '/data', // 基础地址 要请求的url前缀
    // timeout: 10000, // 请求超时时间
    withCredentials: true, 
    headers: {},
})
// request 请求拦截
service.interceptors.request.use((config) => {
    let token = window.localStorage.getItem('token')
    var typestr=typeof(config.data)
    
    if(typestr!="object"){
        if((config.data+"").indexOf("=") == -1 && (config.data+"").indexOf("&") == -1 && config.data!=""){
            if((config.data+"").indexOf("##") == -1){
                config.url=config.url +'/' + config.data
            }else{
                var zsarr=config.data.split("##")
                var zsstr=""
                for(let iitem of zsarr){
                    zsstr=zsstr+'/' + iitem
                }
                config.url=config.url + zsstr
            }
        }else{
            config.url=config.url + '?' + config.data
        }

    }
    
    const url = config.url.replace(config.baseURL, '')
    
    //验证接口白名单(无需token)
    if (whiteList.some(wl => url === wl)) {
        return config
    }
    //加入token
    config.headers['Authorization'] = token
    return config
    }, (error) => {
      Promise.reject(error)
    }
)
//respone 响应拦截
service.interceptors.response.use((response) => {
    const data = response.data
    //状态暂为模拟
   if (data.code == 109 ) {
        //return Promise.reject(data.message)
        Message.error({
            showClose: true,
            message:  '登录过期，请先登录',
            type: 'error',
        })
        //自动跳转登录
        //const roleId = JSON.parse(window.localStorage.getItem('roleId'))
      // localStorage.clear()
      // window.location.href = '/login'
       
    }
    else if (data.code == 401) {
        //return Promise.reject(data.message)
        let a=localStorage.getItem("num")
        
        if(a==0){
            Message.error({
                showClose: true,
                message:  '请先登录',
                type: 'error',
            })
        }
        
        localStorage.setItem("num",1)
        //自动跳转登录
        //const roleId = JSON.parse(window.localStorage.getItem('roleId'))
        localStorage.removeItem("menu")
        localStorage.removeItem("num")
        localStorage.removeItem("userinfo")
        localStorage.removeItem("token")
        window.location.href = '/login'
       
    }
    else if (data.code == 403) {//没有权限访问的页面，直接跳转到指定的页面
        //return Promise.reject(data.message)
        
        //自动跳转登录
        //const roleId = JSON.parse(window.localStorage.getItem('roleId'))
       // window.location.href = '/error/403'
       
    }
     else {
        //抛出异常状态，手动处理
        //return Promise.reject(data.message)
        return data
    }
}, (error) => {
    // 异常处理
   // console.log(error.response)

    if(error.response.status==401){
        let a=localStorage.getItem("num")
        if(a==0){
            Message.error({
                showClose: true,
                message:  '请先登录',
                type: 'error',
            })
        }
        
        localStorage.setItem("num",1)
        //自动跳转登录
        //const roleId = JSON.parse(window.localStorage.getItem('roleId'))
        localStorage.removeItem("menu")
        localStorage.removeItem("num")
        localStorage.removeItem("userinfo")
        localStorage.removeItem("token")
        window.location.href = '/login'
    }
    if(error.response.status==500){
        //return Promise.reject(data.message)
       //自动跳转登录
       //const roleId = JSON.parse(window.localStorage.getItem('roleId'))
       if(error.response.config.url!="/auth/code?" && error.response.config.url!="/api/MinimumMoney/audits?"){
        window.location.href = '/error/500'
       }else{
            Message.error({
                showClose: true,
                message:  '服务器错误，稍后重试！',
                type: 'error',
            })
       }
       
   }
//    if(error.response.status==400){
//         if(error.response.config.url!="/auth/code?"){
//             window.location.href = '/error/400'
//        }
//    }
    return Promise.reject(error.response);
})
export default service