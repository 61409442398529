<template>
    <div class="tagbox">
        <ul class="viewtag">
            <!-- <li :class="path=='/home'?'tagon':''"><router-link style="padding-right:10px;" to="/home">主页</router-link></li> -->
            <li v-for="(tag,index) in taglist" :class="path==tag.path?'tagon':''"><router-link :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }">{{tag.name}} <i class="el-icon-close" @click.prevent.stop="closeitem(index)"></i></router-link></li>
        </ul>
    </div>
</template>
<script>
export default {
    data(){
        return{
            path:"",
            taglist:[]
        }
    },
    created(){

    },
    methods:{
        closeitem(index){
            this.$store.commit("deletetag",index)
        }
    },
    watch:{
        $route(to,from){
       // console.log(from.path);//从哪来
       // console.log(to);//到哪去
        var storearr=this.$store.state.viewtag
        //console.log(storearr.length)
        if(to.path!="/home"){
            if(storearr.length>0){
                var findarr=storearr.find((n)=>(n.path==to.path))
                // console.log(findarr)
                if(findarr==null || findarr==undefined){
                    this.$store.commit("pushtag",to)
                }else{
                    if(JSON.stringify(findarr.query) != '{}' ){
                        var num=0
                        var num2=0
                        for(let item of storearr){
                            if(item.path==findarr.path){
                                num2=num
                            }
                            num++
                        }
                        this.$store.commit("deletetag",num2)
                        this.$store.commit("pushtag",to)
                    }
                }
            }else{
                this.$store.commit("pushtag",to)
            }
        }
        
        this.path=to.path
        this.taglist=this.$store.state.viewtag
        console.log(this.$store.state)
        }
    }
}
</script>
<style scoped>
.tagbox{box-shadow: 1px 0 2px 0 rgba(0,0,0,.05); overflow-x: auto;overflow-y: hidden; background: #ffffff; }
ul.viewtag{ display: block; margin:0; padding:5px; height: 40px; max-width: 200%;}
ul.viewtag li{ display: block; float: left; margin:0 2px; font-size: 12px; }
ul.viewtag li a{display: block;font-size: 12px; text-decoration: none;  color: #999; border-radius: 3px; cursor: pointer; border:#e6e6e6 solid 1px; padding:0 5px 0 10px; height:26px; line-height: 23px;}
ul.viewtag li a i{ margin-left:6px; display: inline-block; width: 20px; height: 20px; text-align: center; line-height: 22px; vertical-align: middle; margin-top:-1px;}
ul.viewtag li a i:hover{ background: #e6e6e6; color: #ffffff;}
ul.viewtag li.tagon a{border:#420e1a solid 1px; background:#471923; color: #ffffff;}
ul.viewtag li.tagon a i:hover{background: #FF7300; color: #ffffff;}
</style>