import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from "./router/index"
import store from "./store/index"
import 'element-ui/lib/theme-chalk/index.css';
import '../theme/index.css';
import Apppage from '@/components/pagenation'
import Popup from '@/components/Popup'
import Province from '@/components/province'
import UploadImg from '@/components/Uploadimg'
import MyVideo from '@/components/myvideo'
import UploadImgbatch from '@/components/Uploadimgbatch'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import echarts from 'echarts'
import VueCookies from 'vue-cookies'

import Msg from '@/components/alert/alert.js';
import filters from './filter/index.js'

import i18n from './i18n';

Vue.filter('changedate', filters.changedate);
Vue.prototype.$echarts = echarts
Vue.use(VueCookies)
Vue.use(ElementUI,{
    size: 'medium',
    i18n: (key, value) => i18n.t(key, value)
});
Vue.use(VueQuillEditor)
Vue.config.productionTip = false
Vue.use(Msg);//弹框提示组件
Vue.component('app-page',Apppage)//分页组件
Vue.component('pop-up',Popup)//弹框组件
Vue.component('province',Province)//省市三级联动
Vue.component('upload-img',UploadImg)//单个图片上传组件
Vue.component('my-video',MyVideo)//视频播放组件
Vue.component('upload-imgbatch',UploadImgbatch)//多个图片上传组件

// 按钮权限
Vue.directive('has', {
  bind: function(el, binding) {
   if (!Vue.prototype.$_has(binding.value)) {
    el.style.display = "none"
   }
  }
 });
 //权限检查方法
 Vue.prototype.$_has = function(value) {
  let isExist=false;
  let getperm=JSON.parse(localStorage.getItem("userinfo"));
  let buttonpermsStr=getperm.roles;

  if(buttonpermsStr==undefined || buttonpermsStr==null){
   return false;
  }
  if(buttonpermsStr[0]=="admin"){
    isExist=true;
  }
  if (buttonpermsStr.indexOf(value) >= 0) {
    isExist=true;
  }
  return isExist;
 };


new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
