import Vuex from "vuex"
import Vue from "vue"

//注册vuex到vue里面
Vue.use(Vuex)

//创建安vuex的仓库对象
let store=new Vuex.Store({
    state:{
        viewtag:[]
    },
    mutations:{//设置值
        pushtag(state,value){
            state.viewtag.push(value)
        },
        deletetag(state,value){//删除当个
            state.viewtag.splice(value,1)
        },
        deleteitem(state,value){
            state.viewtag.remove(value)
        },
        cleartag(state,value){//删除全部
            state.viewtag=[]
        }
    }
})


export default store

