<template>
    <div>
        <el-upload
        class="avatar-uploader"
        action="/data/api/qiNiuContent"
        :headers="headers"
        :show-file-list="false"
        :data="modeldata"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload">
        <img v-if="modeldata[urlname]" :src="modeldata[urlname]" style="border-radius: 10px; overflow: hidden;" class="avatar">
        <i v-else class="myupload avatar-uploader-icon" style="border: #e8e8e8 solid 1px;border-radius: 10px; overflow: hidden;" v-loading="loading">+</i>
        </el-upload>
    </div>
</template>
<script>
export default {
    data() {
      return {
        imageUrl: '',
        loading:false,
        headers:{
          Authorization:"",
        }
      };
    },
    props:{
      modeldata:{
        type:Object
      },
      urlname:{
        type:String,
        default:"url"
      }
    },
    created(){
      //console.log(this.modeldata)
      //this.headers.Authorization=JSON.parse(localStorage.getItem("token"))
    },
    methods: {
      upLoad(){

      },
      handleAvatarSuccess(res, file) {
        //console.log(res)
        this.loading=false
        this.imageUrl =res.data[0];
        
        this.modeldata[this.urlname]= this.imageUrl
        this.$emit("changeurl",this.modeldata[this.urlname]);

      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 6;

        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG 格式和PNG格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        this.loading=true
        return isJPG && isLt2M;
      },
    
    }
  }
</script>
<style>
.el-loading-spinner{ top: 20%!important;}

</style>
<style scoped>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .myupload{ width: 150px; height: 150px; display: block; line-height: 150px!important;}
</style>
