import Vue from "vue"
import { Message } from 'element-ui'
import VueRouter from "vue-router"
import routes from "./route"

Vue.use(VueRouter)

const router=new VueRouter({
	mode:"history",
	routes
})

router.beforeEach((to, from, next) => {//路由跳转前
	// console.log(to)
	//next()
	const token=localStorage.getItem("token")
	if(token==null && to.path != '/login'){
		next("/login")
	}else{
		next()
	}
	let title=""
	if(to.name==null){
		title="Am-Pay平台"
	}else{
		title=to.name + '--Am-Pay平台'
	}
	document.title = title
	
})

export default router
