<template>
    <div>
        <div id="main-box" style="opacity:1;"><img src="../../assets/images/11.jpg" alt=""></div>
        <div id="main-content">
            <div class="login-body animated fadeInLeft">
                <div class="login-main pr">
                    <form action="javascript:;" class="login-form">
                        <h3>Am-Pay平台</h3>
                        <div id="MobileBox" class="item-box">
                            <div class="input-group user-name"> <span class="input-group-addon"><i
                                        class="el-icon-user"></i></span>
                                <input type="text" v-model="loginfrom.username" name="username" class="form-control"
                                    :placeholder="$t('dlm')">
                            </div>
                        </div>
                        <div class="input-group password"> <span class="input-group-addon"><i
                                    class="el-icon-lock"></i></span>
                            <input type="password" v-model="password" name="password" class="form-control"
                                :placeholder="$t('mm')">
                        </div>
                        <div class="input-group password" style="background:none;">
                            <input type="text" v-model="loginfrom.code" name="password"
                                style="width:180px;border-radius: 2px;" class="form-control" :placeholder="$t('yzm')">
                            <img :src="codeUrl" height="40" width="130" style="margin-left:8px;border-radius: 2px;"
                                @click="getCode">
                        </div>
                        <div class="login_btn_panel">
                            <button class="loginbtn" @click="login">{{$t('dl')}}</button>
                            <div class="check-tips"></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        encrypt
    } from '@/util/rsaEncrypt'
    import apiurl from "@/api/public"
    import _api from "@/api/index"
    export default {
        data() {
            return {
                loginnum: 0,
                codeUrl: '',
                buttonpermissions: {},
                token: "",
                imgurl: "",
                password: "",
                loginfrom: {
                    username: "",
                    password: "",
                    rememberMe: true,
                    code: '',
                    uuid: '',
                    ipLock: null
                },
            }
        },
        created() {
            this.getCode()
            if (this.$cookies.isKey("username")) {
                this.loginfrom.username = this.$cookies.get("username")
            }

            var lang = localStorage.getItem("lang")
            if (lang == null) {
                // 默认语言西班牙
                localStorage.setItem("lang", "es_US")
            }
        },
        methods: {
            uuid() {
                var s = [];
                var hexDigits = '0123456789abcdef';
                for (var i = 0; i < 36; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
                }
                s[14] = '4';
                s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
                s[8] = s[13] = s[18] = s[23] = '-';
                this.loginfrom.ipLock = s.join('');
            },
            getCode() {
                _api.get(apiurl.getcode, {}).then(res => {
                    console.info(res)
                    this.codeUrl = res.img
                    this.loginfrom.uuid = res.uuid
                })
            },
            login() { //登录
                if (this.$cookies.isKey("pcuuid")) {
                    this.loginfrom.ipLock = this.$cookies.get("pcuuid")
                    console.log(this.$cookies.get("pcuuid"))
                } else {
                    this.uuid()
                }

                this.loginfrom.password = encrypt(this.password)
                _api.post(apiurl.login, this.loginfrom).then(res => {
                    this.$cookies.set("username", this.loginfrom.username)
                    localStorage.setItem("token", res.token)
                    localStorage.setItem("userinfo", JSON.stringify(res.user))
                    console.log(res.user)
                    _api.get(apiurl.usermenu, {
                        lang:localStorage.getItem("lang")
                    }).then(ress => { //获取用户菜单
                        localStorage.setItem("menu", JSON.stringify(ress))
                        localStorage.setItem("num", 0)
                        this.$router.push("/home")
                    })
                }).catch(err => {
                    this.getCode()
                    this.$notify.error({
                        title: '错误',
                        message: err.data.message
                    });
                })
            }
        }
    }
</script>
<style scoped>
    @import "../../assets/css/login.css";

    #main-content {
        background: url("../../assets/images/dot.png");
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1000;
    }

    .icon-loading {
        display: inline-block;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        vertical-align: -3px;
    }
</style>