<template>
    <div>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="current"
        :page-sizes="sizeList"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    props:{
        total: {
            type: Number,
            default: 1000
        },
        current: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 10
        },
        sizeList: {
            type: Array,
            default: () => {
                return [10, 20, 30, 40]
            }
        },
    },
    methods:{
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.$emit('handleSizeChange', val)
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`)
            this.$emit('handleCurrentChange', val)
        }
    }
}
</script>
<style scoped>

</style>