const en = {
  lang: 'en_US',
  operator:'Operate',
  isDel:'Are you sure you want to delete it?',
  success:'Success',
  delSuccess:'Deleted successfully!',
  addSuccess:'Added successfully!',
  editSuccess:'Edit successfully!',
  error:'Error',
  query:'Search',
  buxian:'No limit',
  home:'Home page',
  createTime: 'Creat time',
  collectionManagement :'Collection management',
  paymentDays:'Loan stage',
  bookName:'Loan stage name',
  startNum:'Start',
  endNum:'End',
  startLgend:'The end must be bigger than the start',
  addPaymentDays:'Add loan stage',
  editPaymentDays:'Edit loan stage',
  pleaseBookName:'Please enter the name of the loan stage',
  classifyName:'Classification name of collection log',
  addClassify:'Add classification of collection log',
  editClassify:'Edit classification of collection log',
  pleaseClassifyName:'Por favor introduzca el nombre de la clasificación del registro de gestiones',
  classify:'Classification of collection log',
  group:'Collection group',
  groupName:'Gruop name',
  zhuguan:'Supervisor',
  pleaseZhuguan:'Please select supervisor',
  pleaseZhangqi:'Please select the name of the loan stage',
  addgroup:'Add collection group',
  editgroup:'Edit collection group',
  mubiao:'Objective',
  zhuguanname:'Supervisor name',
  cxxzcy:'Enquiry team members',
  qxzzgmc:'Please select the name of the supervisor',
  qxzzqmc:'Please select the name of the loan stage',
  qsrxzmc:'Please enter the name of the group',
  mbbnwk:'Target cannot be empty',
  orderManagement:'Collection order management',
  orderInfo:'Order collection information',
  phone:'Phone number',
  mchOrderNo: 'Order number',
  email: 'Email address',
  userName: 'Name',
  cantidad: 'Due amount',
  days: 'Loan period',
  dkje: ' Loan amount',
  fkje: 'Loan amount released',
  fwf : 'Service fee',
  lx: 'Interest',
  sje: 'Loan amount received',
  fksj : 'Loan release date',
  lastTime: ' Last repayment date',
  skzh : 'Receipt account number',
  amount: 'Amount',
  plannedRepaymentAmount: 'Repayment amount',
  channel: 'Channel',
  overdueDay: 'Overdue days',
  expireTime: 'Expiration date',
  exclusiveLinks:'Repayment link',
  stats: 'Order status',
  guanxi: 'Relationship',
  qxzguanxi: 'Please select the relationship',
  name: 'Name',
  jjlxr: 'Emergency contact',
  csy: 'Collection agent',
  ssje: 'Loan amount received',
  addInfo: 'Add note',
  editInfo: 'Edit note',
  telTime: 'Dial date',
  rlTime: 'Promise date',
  fl: 'Classification',
  yg: 'Employee',
  beizhu: 'Remark',
  xzTime: 'Select the date',
  qxzflmc: 'Please select the classification name',
  yqddgl: 'Overdue order mamagment',
  time: 'Time range',
  fp: 'Assignment',
  ckrq: 'Deposit date',
  qxzcsymc: 'Please select the name of the agent',
  qgxfpdd: 'Please check the orders to be assigned!',
  wbddgl: 'External orders managment',
  cpm:'Product name',
  value1: 'Today application data',
  value2: 'Date',
  value3: 'New users',
  value4: 'Number of registrations',
  value5: 'Number of applications',
  value6: 'Number of rejected validations',
  value7: 'Number of approved validations',
  value8: 'Approve Rate',
  value9: 'Number of approved loans today',
  value10: 'Number of rejected loans today',
  value11: 'Average loan amount today',
  value12: 'Total number of approved loans',
  value13: 'Total number of rejected loans',
  value14: 'Average amount of all loans',
  value15: 'Total number of orders',
  value16: 'Number of refunded orders',
  value17: 'Overdue rate',
  value18: 'Number of orders',
  value19: 'Total number of unpaid due orders',
  value20: 'Total amount of unpaid due orders',
  value21: 'Median number audited today',
  value22: 'Total audit median number',
  dlm: 'Login name',
  mm: 'Password',
  yzm: 'Verification code',
  dl: 'Login',
  yhgl: 'User managment',
  gjz: 'Keyword',
  qddh: 'Channel/Phone number',
  yhzt: 'User status',
  qxzyhzt: 'Please select user status',
  drz : 'unverified',
  rzz : 'Certification in progress',
  dqy : 'Pending contract',
  shz : 'Revision in process',
  shjj : 'Revision rejected',
  fksb : 'Loan release failed',
  jj : 'Pay off',
  wdq : 'Unexpired',
  dqr : 'Due date',
  yq : 'Overdue',
  yfk : 'Loan released',
  yhk : 'Paid off',
  whk : 'No reimbursement',
  ddbj : 'Order rejected',
  channel : 'Channel',
  status : 'Status',
  zhdlsh: 'Last login time',
  rzxx: 'Certification information',
  yhrzxx: 'User authentication information',
  yhdh:'User phone number',
  birthday:'Birthday',
  addressAll:'Address',
  gender:'Gender',
  fatherLastName:'Paternal surname',
  motherLastName:'Their mother surname',
  postalCode:'Postal code',
  fullName:'Name',
  idNumber:'Identification number',
  district:'Region',
  educationName:'Education level',
  employmentName:'Employment status',
  comoanyName:'Company name',
  comoanyPhone:'Company phone number',
  workingYearName:'Seniority of employment',
  monthlySalaryName:'Monthly income',
  workingDaysName:'Payday',
  idCardPositive:'Back of credential',
  idCardReverse:'Front of credential',
  faceUrl:'Face',
  jzz:'Loading',
  zqyq:'Extension and overdue users management',
  type:'Classification',
  qxztype:'Please select the type',
  zq:'Extension',
  yq:'Overdue',
  day:'Day numbers',
  sourceMoney :'Repayment sources',
  yhhkgl: 'User repayment management',
  hbj :'Capital repayment',
  hzq : 'Extension repayment',
  hwbdd : 'External orders repayment',
  orderNum : 'Repayment account number',
  typeName: 'Repayment method',
  iden: 'Repayment Type',
  ddgl: 'Orden managment',
  orderNo : 'Payment flow number',
  xtgl: 'System administration',
  ncdh : 'Nickname/Phone number',
  nc:'Nickname',
  role: 'Role',
  qxzrole : 'Please select the role',
  adduser: 'Add user',
  edituser :'Edit user',
  gsxz : 'Corresponding group',
  isczmm : 'Are you sure you want to reset the password?',
  jsdl : 'Unlock login',
  dlzh : 'login ID',
  qxzfz : 'Please select a group',
  jihuo: 'Activate',
  suoding: 'Lock',
  nan :'Male',
  nv: 'Female',
  qsryhm: ' Please enter the user name',
  qsryhnc : 'Please enter the user nickname',
  qsryxdz : 'Please enter the email address',
  qsrzqyxdz : 'Please enter the correct email address',
  qsrsjh: ' Please enter the phone number',
  jscg : 'Unlocked successfully!',
  czcg : 'Reset successfully!',
  czsb : 'Reset failed!',
  shibai: 'Failure',
  czcg : 'Operation successfully!',
  grzx  : 'Personal center',
  xgmm : 'Change password',
  lmm : 'Old password',
  xmm : 'New password',
  qrxmm : 'Confirm new password',
  baoc : 'Save',
  qsrlmm : 'Please enter the old password!',
  qsrxmm : 'Please enter the new password!',
  llmmbyz : 'The two passwords entered do not match!',
  xgcg : 'Modified successfully!',
  jegl : 'Role Management',
  addrole : 'Add role',
  editrole : 'Edit role',
  rolename : 'Role name',
  roleLe : 'Role level',
  data : 'Data range',
  quanbu : 'All',
  benji : 'This level',
  zidy : 'Customization',
  rolems : 'Role description',
  tianj : 'Add',
  qxzsjfw : 'Please select a range of data',
  qcxxzsjfw : 'Please re-select the data range',
  qxz : 'Please select',
  xiugai : 'Modify',
  fpcd : 'Menu assignment',
  bccdcg : 'Save menu successfully!',
  cpgl:'Product management',
  addcp :'Add product',
  editcp : 'Edit product',
  cpmc:'Product name',
  dkqx:'Loan period/days',
  zqfl:'Extension fee',
  fxfl:'Penalty interest rate',
  flsqfs:'Penalty interest charging method',
  fk1:'Servicing fee and interest deducted on release of loans',
  fk2:'Service fee deducted on release of loans, interest charged at overdue',
  fk3:'Service fee and interest charged at overdue',
  sytj:'Terms and conditions of use/Number',
  jxks:'Start of interest accrual',
  fkdr:'Loan release date (N-1)',
  fkcr:'Next day of loan release (N)',
  tqhk:'Interest accrual method for early repayment',
  qxjx:'Full-cycle interest accrual',
  sjsyjz:'Actual use of interest accrued',
  qiyong :'Activate',
  jinyong:'Disable',
  qq1:'Please select the interest charging method',
  qq2:'Please select the start of the interest accrual period',
  qq3:'Please select the repayment interest accrual method',
  qxgl:'Permission management',
  addzcd:' Add main menu',
  cdbt:'Menu title',
  tup:'Icon',
  qxlj:'Permission path',
  qxbs:'Permission mark',
  paix:'Sort by',
  addzjd:'Add subnodes',
  addcd :'Add menu',
  cdmc:'Menu name',
  qxurl:'URL permission',
  editcd:'Edit menu',
  zhucd:'Main menu',
  zcd:'Submenu',
  gongn:'Funtion',
  sysparam:'System parameter configuration',
  paramName:'Parameter name',
  paramValue:'Parameter value',
  editSys:'Edit system parameters',
  edgl:'Credit limit management',
  tegz:'Rules for increasing the credit limit',
  jegz:'Rules for decreasing the credit limit',
  added:'Add credit limit',
  edited:'Edit credit limit',
  jkcs:'Loan number',
  yqcstype:'Types of overdue payment numbers',
  yqcs:'Overdue number',
  zhycjktype:'Types of overdue days on the last loan',
  zhycjkts:'Number of days overdue on last loan',
  dzed:'Credit limit adjustment',
  qxzyqlx:'Please select the type of overdue times',
  qxzyqcslx:'Please select the type of overdue times',
  qxzzhycjktslx:'Types of overdue days on the last loan',
  v1:'Equal to',
  v2:'Not equal to',
  v3:'More than',
  v4:'More than or equal to',
  v5:'Less than',
  v6:'Less than or equal to',
  v7:'Between',
  v8:'No in between',
  v9:'Included in',
  v10:'Not included in',
  loggl:'Log managment',
  yonghum:'User name',
  jieguo:'Result',
  liulq:'Browser',
  shic:'Duration',
  wdcsdd:'My orders',
  zczds:'Total number of active orders',
  jrchds:'Number of repayment orders today',
  jrchl:'Repayment rate',
  zcje:'Loan amount',
  chje:'Repayment amount',
  z:'至',
  kssj:'Start time',
  jssj:'End time',
  tjcsjl:'Add collection log',
  jbxx:'Basic information',
  ddxq:'Order details',
  gzxx:'Job Information',
  lxr1xm:'Contact 1: Name',
  lxr2xm:'Contact 2：Name',
  lxr1dh:'Contact 1：phone',
  lxr2dh:'Contact 2：phone',
  lxr1gx:'Contact 1：relation',
  lxr2gx:'Contact 2：relation',
  sfxx:'Identity Information',
  txl:'Contacts',
  gzjl:'Remark records',
  csjl:'Collection remarks',
  cjrq:'Creation date',
  qxzyggx:'Please select a relationship',
  qsrxm:'Please enter your name',
  qsrdh:'Please enter your phone number',
  qxzrq:'Please select the date',
  qsrbz:'Please enter a note',
  qxzdd:'Please select a order',
  zclstj:'Historical collection statistics',
  chjel:'Repayment amount rate',
  cscgjl:'Record of successful repayments',
  cscgyqjl:'I successfully collected the overdue order',
  zctj:'Collection statistics',
  home1:'Re-loan users',
  home2:'Lending data',
  home3:'Data of today',
  home4:'Historical data',
  home5:'Payment data',
  home6:'Due order data of today',
  home7:'Due order data of new loan customers today',
  home8:'Due order data of re-loan customers today',
  home9:'On-Loan data',
  login1:'You switch platform environment, please log out and log in again',
  login2:'switch the language',
  login3:'confirm',
  login4:'Exit the success！',

  dc1:'Daily data summary',
  dc2:'Number of orders assigned of the day：',
  dc3:'Repayment rate of the day：',
  dc4:'New orders repayment rate of the day：',
  dc5:'Repayment rate yesterday：',
  dc6:'Repayment rate of the month：',
  dc7:'Repayment numbers of the day：',
  dc8:'Number of orders assigned yesterday：',
  dc9:'Repayment rate of new orders of the month：',
  dc10:'Current pending orders for repayment：',
  dc11:'Number of repayments yesterday：',
  dc12:'Employee name',
  dc22:'Number of orders assigned of the day',
  dc13:'Current pending orders',
  dc14:'Repayment numbers of the day',
  dc15:'New orders repayment rate of the day',
  dc16:'Amount of pending orders',
  dc17:'Repayment rate of the month',
  dc18:'Repayment rate of new orders of the month',
  dc19:'Number of orders assigned yesterday',
  dc20:'Number of repayments yesterday',
  dc21:'Repayment rate yesterday',

  der1:'Payment days configuration',
  der2:'Collection remission',
  der3:'Add remission',
  der4:'Edit remission',
  der5:'Percentage of maximum remission of each order',
  der6:'Maximum number of orders with remission of the month',
  der7:'Estimated number in the next 7 days',
  der8:'Estimated number of cases',

  call0:'Call back the real-time data report',
  call1:'Repayments',
  call2:'Collect date：',
  call3:'All',
  call4:'Number of new orders of the day：',
  call5:'Number of new orders of new loan customers：',
  call6:'Number of new orders of re-loan customers：',
  call7:'Current number of repayments of new loan customers：',
  call8:'Current number of repayments of re-loan customers：',
  call9:'Current repayment rate of new loan customers：',
  call10:'Current repayment rate of re-loan customers：',
  call11:'Current total repayment rate：',

  loan1:'Post-loan overall data page',
  loan2:'Lending date',
  loan3:'Loan amount',
  loan7:'In the rush case',

  oter:'Reimbursement amount',
  oter1:'List of loan failure orders',
  oter2:'Lending again',
  oter3:'Loan success',

  txl1:'family',
  txl2:'general',

  loan4:'The first credit',
  loan5:'After loan',

  qux:'cancel',
  cffkqr:'Repeat loan, please confirm the information',
  fkje1:'Amount of loan：',
  orderNo1:'order number： ',
  phone1:'phone： ',
  fkz1:'In the lending...',

  cd1:'view details',
  cd2:'In the rush for historical statistics details',
  callnum:'Number of collection',
  pingt:'platform',
  iscancelar:'Whether to settle the order',
}
export default en;