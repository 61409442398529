<template>
    <div>
        <el-select v-model="nprovince" size="small" @change="checkprovince" style="width:140px;margin-right:5px;" placeholder="请选择省份">
            <el-option label="请选择省份" :value="0"></el-option>
            <el-option
            v-for="item in address"
            :key="item.id"
            :label="item.name"
            :value="item.id">
            </el-option>
        </el-select>
         
        <el-select v-model="ncity" size="small" @change="checkcity" style="width:140px;margin-right:5px;" placeholder="请选择城市">
            <el-option  label="请选择城市" :value="0"></el-option>
            <el-option
            v-for="item in citylist"
            :key="item.id"
            :label="item.name"
            :value="item.id">
            </el-option>
        </el-select>
        
        <el-select v-model="ndistrict" @change="checkdis" size="small" style="width:140px;" placeholder="请选择地区">
            <el-option  label="请选择地区" :value="0"></el-option>
            <el-option
            v-for="item in districtlist"
            :key="item.id"
            :label="item.name"
            :value="item.id">
            </el-option>
        </el-select>
    </div>
</template>
<script>
import apiurl from "@/api/sys"
import _api from "@/api/index"
export default {
    data(){
        return{
            nprovince:0,
            ncity:0,
            ndistrict:0,
            address:[],
            citylist:[],//城市选择列表
            districtlist:[],//地区选择列表
        }
    },
    props:{
        province:{
            type:Number,
            default:0
        },
        city:{
            type:Number,
            default:0
        }
        ,
        haveno:{
            type:Boolean,
            default:true
        }
        ,
        district:{
            type:Number,
            default:0
        }
    },
    
    created(){
        
        var nowaddress=JSON.parse(localStorage.getItem("address"))
        this.nprovince=this.province
        this.ncity=this.city
        this.ndistrict=this.district
        if(nowaddress==null){//如果没有缓存的地址信息
            this.getAddress()
        }else{
            this.address=nowaddress
            if(this.nprovince>0){//主要是为了在编辑时默认显示了省，然后城市有下拉选项
            this.citylist=(this.address.find((n)=>(n.id==this.nprovince))).children
            }
            if(this.ncity>0){
                this.districtlist=(this.citylist.find((n)=>(n.id==this.ncity))).children
            }
        }
        
       
        
    },
    methods:{
        getAddress(){//获取地址
            _api.get(apiurl.address,{}).then(res=>{
                console.log(res)
                console.log(9900000)
                if(res.code==200){
                    this.address=res.data
                    localStorage.setItem("address",JSON.stringify(this.address))
                    if(this.nprovince>0){//主要是为了在编辑时默认显示了省，然后城市有下拉选项
                    this.citylist=(this.address.find((n)=>(n.id==this.nprovince))).children
                    }
                    if(this.ncity>0){
                        this.districtlist=(this.citylist.find((n)=>(n.id==this.ncity))).children
                    }
                }
                
            })
        },
         checkprovince(id){//选择了省份时执行
            this.citylist=[]
            this.ncity=0
            this.districtlist=[]
            this.ndistrict=0
            if(id!=""){
                this.citylist=(this.address.find((n)=>(n.id==id))).children//通过id值查找数据中对应的对象
            }
            this.$emit("province",this.nprovince)
            this.$emit("city",0)
            this.$emit("district",0)
           
        },
        checkcity(id){//选择了城市时执行
            this.districtlist=[]
            this.ndistrict=0
            if(id!=""){
            this.districtlist=(this.citylist.find((n)=>(n.id==id))).children//通过id值查找数据中对应的对象
            }
            //console.log(this.districtlist)
            this.$emit("city",this.ncity)
            this.$emit("district",0)
        },
        checkdis(id){
            this.$emit("district",this.ndistrict)
        },
        
    }
}
</script>
<style scoped>

</style>