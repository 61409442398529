export default{
    checkphone:function(phone){//验证手机号
        if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))){ 
            return false; 
        }else{
            return true;
        }
    },
    handleNumber:function(num){//数值过万处理
        if(num<10000){
            return num
        }else{
            let frist_init=num.toString().slice(0,num.length - 4);//取四位之外的数值
            let last_four=num.toString().substr(num.length - 4);//取后四位
            return (frist_init*1+(last_four*1/10000)).toFixed(2)+"w"
        }
    },
    changedate:function(value){//时间戳转为日期
        var date = new Date(value * 1);
        var YY = date.getFullYear() + '-';
        var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
        var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return YY + MM + DD + " " + hh + mm + ss;
    }
}
 