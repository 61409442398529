<template>
    <div>
        <el-upload style="display:inline-block;vertical-align: middle;" ref="uploads" :file-list="fileList"
            action="/data/api/qiNiuContent" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess"
            list-type="picture-card">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
                <img style="vertical-align: middle;" class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                <span class="el-upload-list__item-actions">
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                        <i class="el-icon-delete"></i>
                    </span>
                </span>
            </div>
        </el-upload>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                myfilelist: [],
                fileList: [],
                urlObj: {
                    response: {
                        data: []
                    }
                },
                dialogImageUrl: '',
                dialogVisible: false,
                disabled: false,
                urlArr: [],
            }
        },
        props: {
            yuanlist: {
                type: String,
                default: null
            }
        },
        created() {
            if (this.yuanlist != null && this.yuanlist != '') {
                this.items = this.yuanlist.split(",");
                this.items.forEach(val => {
                    this.myfilelist.push(val)
                    this.urlObj.url = val;
                    this.urlObj.response.data.push(val);
                    this.urlArr.push(val)
                    this.fileList.push(this.urlObj);
                    this.urlObj = {
                        response: {
                            data: []
                        }
                    };
                });

            }
        },
        methods: {
            changeurl() {
                var fileurl = this.myfilelist.join(",")
                this.$emit("changeurl", fileurl)

            },
            handleAvatarSuccess(res, file, fileList) {
                //console.log(fileList)
                if (res.code == 200) {
                    this.myfilelist.push(res.data[0])
                }
                this.changeurl()
            },
            beforeAvatarUpload(file) {
                console.log(this.myfilelist.length)
                if(this.myfilelist.length>=5){
                    this.$message.error("最多只能选择5张！")
                    return false
                }
                const isJPG = file.type === 'image/jpeg' || file.type === "image/png";
                const isLt2M = file.size / 1024 / 1024 < 2;
                
                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG 格式和PNG格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return false
                }
                this.loading = true
                return isJPG && isLt2M;
            },
            handleRemove(file) {
                console.log(this.$refs.uploads.uploadFiles);
                var num = 0
                var ind = 0
                for (let item of this.myfilelist) {
                    if (item == file.response.data[0]) {
                        ind = num
                    }
                    num++
                }
                this.myfilelist.splice(ind, 1)
                this.$refs.uploads.uploadFiles.splice(ind, 1)

                this.changeurl()

                // this.$refs.uploads.clearFiles(file)

            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleDownload(file) {
                console.log(file);
            },
        }
    }
</script>
<style>
    .el-upload-list--picture-card .el-upload-list__item {
        margin: 0 8px 0 0 !important;
    }
</style>