const es = {
  lang: 'es_US',
  operator:'Gestionar',
  isDel:'¿Seguro que lo quieres eliminar?',
  success:'Éxito',
  delSuccess:'¡Borrado con éxito!',
  addSuccess:'¡Añadido con éxito!',
  editSuccess:'¡Editado con éxito!',
  error:'Error',
  query:'Consulta',
  buxian:'No limitado',
  home:'Página de inicio',
  createTime: 'Hora de creación',
  collectionManagement :'Gestión de cobranza',
  paymentDays:'Etapa de préstamo',
  bookName:'Nombre de etapa',
  startNum:'Inicie',
  endNum:'Finalizar',
  startLgend:'El final debe ser mayor que el principio',
  addPaymentDays:'Añadir etapa del préstamo',
  editPaymentDays:'Editar la etapa del préstamo',
  pleaseBookName:'Por favor introduzca el nombre de la etapa del préstamo',
  classifyName:'Nombre de la clasificación del registro de gestiones',
  addClassify:'Añadir clasificación del registro de gestiones',
  editClassify:'Editar clasificación del registro de gestiones',
  pleaseClassifyName:'Por favor introduzca el nombre de la clasificación del registro de gestiones',
  classify:'Clasificación del registro de gestiones',
  group:'Grupo de cobranza',
  groupName:'Nombre del grupo',
  zhuguan:'Supervisor',
  pleaseZhuguan:'Por favor seleccione supervisor',
  pleaseZhangqi:'Por favor seleccione el nombre de la etapa del préstamo',
  addgroup:'Añadir grupo de cobranza',
  editgroup:'Editar grupo de cobranza',
  mubiao:'Meta',
  zhuguanname:'Nombre del supervisor',
  cxxzcy:'Miembros del grupo de investigación',
  qxzzgmc:'Por favor seleccione el nombre del supervisor',
  qxzzqmc:'Por favor seleccione el nombre de la etapa del préstamo',
  qsrxzmc:'Por favor introduzca el nombre del grupo',
  mbbnwk:'El objetivo no puede estar vacío',
  orderManagement:'Gestión de órdenes',
  orderInfo:'Información de cobranza de órdenes',
  phone:'Número telefónico',
  mchOrderNo: 'Número de orden',
  email: 'Correo electrónico',
  userName: 'Nombre',
  cantidad: 'Monto a pagar',
  days: 'Plazo de préstamo',
  dkje: ' Monto de préstamo',
  fkje: 'Monto del préstamo liberado',
  fwf : 'Tasa de servicio',
  lx: 'Interés',
  sje: 'Monto del préstamo recibido',
  fksj : 'Fecha de liberación del préstamo',
  lastTime: ' Última fecha de reembolso',
  skzh : 'Cuenta de recibo del préstamo',
  amount: 'Monto',
  plannedRepaymentAmount: 'Cantidad de reembolso',
  channel: 'Canal',
  overdueDay: 'Días de retraso',
  expireTime: 'Fecha de vencimiento',
  exclusiveLinks:'Enlace de pago',
  stats: 'Estado del órden',
  guanxi: 'Relación',
  qxzguanxi: 'Por favor seleccione una relación',
  name: 'Nombre',
  jjlxr: 'Contacto de emergencia',
  csy: 'Agente de cobranza',
  ssje: 'Monto del préstamo recibido',
  addInfo: 'Añadir nota',
  editInfo: 'Editar nota',
  telTime: 'Fecha de llamada',
  rlTime: 'Fecha de promesa',
  fl: 'Clasificación',
  yg: 'Empleado',
  beizhu: 'Nota',
  xzTime: 'Seleccione la fecha',
  qxzflmc: 'Por favor seleccione el nombre de la clasificación',
  yqddgl: 'Gestión de órdenes atrasados',
  time: 'Rango de tiempo',
  fp: 'Asignación',
  ckrq: 'Fecha de depósito',
  qxzcsymc: 'Por favor seleccione el nombre del agente',
  qgxfpdd: '¡Por favor marque los órdenes para asignar!',
  wbddgl: 'Gestión de órdenes externos',
  cpm:'Nombre del producto',
  value1: 'Datos de la aplicación de hoy',
  value2: 'Fecha',
  value3: 'Nuevos usuarios',
  value4: 'Número de registros',
  value5: 'Número de solicitudes',
  value6: 'Número de validaciones rechazadas',
  value7: 'Número de validaciones aprobadas',
  value8: 'Tasa de aprobación',
  value9: 'Número de préstamos aprobados hoy',
  value10: 'Número de préstamos rechazados hoy',
  value11: 'Monto promedio de préstamos hoy',
  value12: 'Número total de préstamos aprobados',
  value13: 'Número total de préstamos rechazados',
  value14: 'Monto promedio de todos los préstamos',
  value15: 'Número total de órdenes',
  value16: 'Número de órdenes reembolsados',
  value17: 'Tasa de atrasos',
  value18: 'Cantidad de órdenes',
  value19: 'Número total de órdenes no vencidos y no pagados',
  value20: 'Monto total de órdenes no vencidos y no pagados',
  value21: 'Moderados hoy',
  value22: 'Número total de auditorías',
  dlm: 'Nombre de usuario',
  mm: 'Contraseña',
  yzm: 'Código de verificación',
  dl: 'Inicio de sesión',
  yhgl: 'Gestión de usuario',
  gjz: 'Palabra clave',
  qddh: 'Canal/Número telefónico',
  yhzt: 'Estado del usuario',
  qxzyhzt: 'Por favor seleccione el estado del usuario',
  drz : 'Pendiente de certificación',
  rzz : 'Certificación en proceso',
  dqy : 'Contrato pendiente',
  shz : 'Revisión en proceso',
  shjj : 'Revisión rechazada',
  fksb : 'Liberación del préstamo fallida',
  jj : 'Liquidado',
  wdq : 'No vencido',
  dqr : 'Fecha de vencimiento',
  yq : 'Atrasado',
  yfk : 'Préstamo liberado',
  yhk : 'Pagado',
  whk : 'No reembolsado',
  ddbj : 'Órden rechasado',
  channel : 'Canal',
  status : 'Estado',
  zhdlsh: 'Última vez de inicio de sesión',
  rzxx: 'Información de certificación',
  yhrzxx: 'Información de autentificación del usuario',
  yhdh:'Teléfono del usuario',
  birthday:'Fecha de nacimiento',
  addressAll:'Domicilio',
  gender:'Género',
  fatherLastName:'Apellido paterno',
  motherLastName:'Apellido materno',
  postalCode:'Código postal',
  fullName:'Nombre',
  idNumber:'Número de identificación',
  district:'Región',
  educationName:'Nivel de educación',
  employmentName:'Situación laboral',
  comoanyName:'Nombre de la empresa',
  comoanyPhone:'Teléfono de la empresa',
  workingYearName:'Antigüedad de trabajo',
  monthlySalaryName:'Ingreso mensual',
  workingDaysName:'Día de nómina',
  idCardPositive:'Dorso de credencial',
  idCardReverse:'Frente de credencial',
  faceUrl:'Rostro',
  jzz:'Cargando',
  zqyq:'Gestión de usuarios de extensiones y atrasados',
  type:'Clasificación',
  qxztype:'Por favor seleccione el tipo',
  zq:'Extensión',
  yq:'Atrasado',
  day:'Número de días',
  sourceMoney :'Fuentes de reembolso',
  yhhkgl: 'Gestión de reembolsos de usuarios',
  hbj :'Reembolso del capital',
  hzq : 'Reembolso de extensión ',
  hwbdd : 'Reembolso de órdenes externos',
  orderNum : 'Número de cuenta de reembolso',
  typeName: 'Forma de reembolso',
  iden: 'Tipo de reembolso',
  ddgl: 'Gestión de órdenes',
  orderNo : 'Folio de pago',
  xtgl: 'Administración del sistema',
  ncdh : 'Apodo/Número telefónico',
  nc:'Apodo',
  role: 'Rol',
  qxzrole : 'Por favor seleccione un rol',
  adduser: 'Añadir usuario',
  edituser :'Editar usuario',
  gsxz : 'Grupo correspondiente',
  isczmm : '¿Seguro que quieres restablecer la contraseña?',
  jsdl : 'Desbloquear el inicio de sesión',
  dlzh : 'Entrar a cuenta',
  qxzfz : 'Por favor seleccione un grupo',
  jihuo: 'Activar',
  suoding: 'Bloqueo',
  nan :'Hombre',
  nv: 'Mujer',
  qsryhm: ' Por favor introduzca su nombre de usuario',
  qsryhnc : 'Por favor introduzca su apodo de usuario',
  qsryxdz : 'Por favor introduzca su correo electrónico',
  qsrzqyxdz : 'Por favor introduzca su correo electrónico correcta',
  qsrsjh: ' Por favor introduzca su número telefónico',
  jscg : '¡Desbloqueado exitoso!',
  czcg : '¡Restablecimiento exitoso!',
  czsb : '¡Restablecimiento fallido!',
  shibai: 'Falla',
  czcg : '¡Operación exitosa!',
  grzx  : 'Centro personal',
  xgmm : 'Cambiar contraseña',
  lmm : 'Contraseña antigua',
  xmm : 'Contraseña nueva',
  qrxmm : 'Confirmar la nueva contraseña',
  baoc : 'Guardar',
  qsrlmm : '¡Por favor introduzca la contraseña antigua!',
  qsrxmm : '¡Por favor introduzca la contraseña nueva!',
  llmmbyz : '¡Las dos contraseñas ingresaste no coinciden!',
  xgcg : '¡Modificado con éxito!',
  jegl : 'Gestión de roles',
  addrole : 'Añadir rol',
  editrole : 'Editar rol',
  rolename : 'Nombre del rol',
  roleLe : 'Nivel de rol',
  data : 'Rango de datos',
  quanbu : 'Todo',
  benji : 'Este nivel',
  zidy : 'Personalización',
  rolems : 'Descripción del rol',
  tianj : 'Añdir',
  qxzsjfw : 'Por favor seleccione un rango de datos',
  qcxxzsjfw : 'Por tornar favor seleccione un rango de datos',
  qxz : 'Por favor seleccione',
  xiugai : 'Modificar',
  fpcd : 'Asignación del menú',
  bccdcg : '¡Guarda el menú exitoso!',
  cpgl:'Gestión de productos',
  addcp :'Añadir producto',
  editcp : 'Editar producto',
  cpmc:'Nombre del producto',
  dkqx:'Plazo de préstamo/días',
  zqfl:'Tasa de extensión',
  fxfl:'Tasa de interés de penalización',
  flsqfs:'Método de cobro de intereses de penalización',
  fk1:'Tasa de servicio e intereses deducidas al liberar los préstamos',
  fk2:'Tasa de servicio deducida al liberar los préstamos, intereses cobrados al vencimiento',
  fk3:'Tasa de servicio e intereses cobrados al vencimiento',
  sytj:'Condiciones de uso/Número',
  jxks:'Inicio del devengo de intereses',
  fkdr:'Fecha de liberación del préstamo (N-1)',
  fkcr:'Próximo día de liberación de prétamo (N)',
  tqhk:'Método de devengo de intereses por reembolso anticipado',
  qxjx:'Devengo de intereses de ciclo completo',
  sjsyjz:'Uso real de los intereses devengados',
  qiyong :'Activar',
  jinyong:'Deshabilitar',
  qq1:'Por favor seleccione el método de cobro de intereses',
  qq2:'Por favor seleccione el inicio del devengo de intereses',
  qq3:'Por favor seleccione el método de devengo de intereses de reembolso',
  qxgl:'Gestión de permisos',
  addzcd:' Añadir menú principal',
  cdbt:'Título del menú',
  tup:'Ícono',
  qxlj:'Ruta de permisos',
  qxbs:'Marca de permisos',
  paix:'Ordenar por',
  addzjd:'Añadir subnodus',
  addcd :'Añadir menú',
  cdmc:'Nombre del menú',
  qxurl:'Permiso de URL',
  editcd:'Editar de menu',
  zhucd:'Menú principal',
  zcd:'Submenú',
  gongn:'Función',
  sysparam:'Configuración de parámetros del sistema',
  paramName:'Nombre del parámetro',
  paramValue:'Valores de los parámetros',
  editSys:'Editar los parámetros del sistema',
  edgl:'Gestión del límite de crédito',
  tegz:'Reglas para aumentar el límite de crédito',
  jegz:'Reglas para reducir el límite de crédito',
  added:'Añdir el límite de crédito',
  edited:'Editar el límite de crédito',
  jkcs:'Número de préstamos',
  yqcstype:'Tipos de números de pagos atrasados',
  yqcs:'Número de atrasos',
  zhycjktype:'Tipos de días atrasados del último préstamo',
  zhycjkts:'Número de días de retraso del último préstamo',
  dzed:'Ajuste del límite de crédito',
  qxzyqlx:'Por favor seleccione el tipo de tiempos de retraso',
  qxzyqcslx:'Por favor seleccione el tipo de tiempos de retraso',
  qxzzhycjktslx:'Tipos de días atrasados del último préstamo',
  v1:'Igual a',
  v2:'No es igual a',
  v3:'Mayor que',
  v4:'Mayor que o igual a',
  v5:'Menos de',
  v6:'Menos de o igual a',
  v7:'Entre',
  v8:'No entre',
  v9:'Incluido en',
  v10:'No incluido en',
  loggl:'Gestión de registros de gestiones',
  yonghum:'Nombre de usuario',
  jieguo:'Resultado',
  liulq:'Navegador',
  shic:'Duración',
  wdcsdd:'Mis órdenes',
  zczds:'Número total de órdenes activos',
  jrchds:'Número de órdenes reembolsados de hoy',
  jrchl:'Tasa de reembolso de hoy',
  zcje:'Monto del préstamo',
  chje:'Monto reembolsado',
  z:'至',
  kssj:'Hora de inicio',
  jssj:'Hora de finalización',
  tjcsjl:'Añadir registro de gestiones',
  jbxx:'Información básica',
  ddxq:'Detalle de órdenes',
  gzxx:'Información laboral',
  lxr1xm:'Contacto 1: Nombre ',
  lxr2xm:'Contacto 2: Nombre ',
  lxr1dh:'Contacto 1: Teléfono',
  lxr2dh:'Contacto 2: Teléfono',
  lxr1gx:'Contacto 1: relación',
  lxr2gx:'Contacto 2: relación',
  sfxx:'Información de identidad',
  txl:'Lista de contactos',
  gzjl:'Historial de registro de gestiones',
  csjl:'Registro de gestiones',
  cjrq:'Fecha de creación',
  qxzyggx:'Por favor seleccione una relación',
  qsrxm:'Por favor introduzca tu nombre',
  qsrdh:'Por favor introduzca tu número de teléfono',
  qxzrq:'Por favor seleccione la fecha',
  qsrbz:'Por favor introduzca una nota',
  qxzdd:'Por favor seleccione un órden',
  zclstj:'Estadística histórica de cobranza',
  chjel:'Tasa de montos reembolsados',
  cscgjl:'Registro de reembolsos exitosos',
  cscgyqjl:'Mi colección fue exitosa órdenes atrasadas',
  zctj:'Estadística de cobranza',
  home1:'Usuarios de préstamos consecutivos',
  home2:'Datos de préstamos',
  home3:'Dato de hoy',
  home4:'Datos históricos',
  home5:'Datos de reembolso',
  home6:'Datos de órdenes vencidos hoy',
  home7:'Datos de órdenes vencidos de clientes de préstamo nuevo hoy',
  home8:'Datos de órdenes vencidos de clientes de préstamo consecutivo hoy',
  home9:'Datos del préstamo',
  login1:'Cambiaste de entorno de plataforma, por favor cierra sesión y vuelve a iniciar sesión',
  login2:'Cambiar el idioma',
  login3:'determinar',
  login4:'¡Salida exitosa!',

  dc1:'Resumen de datos diarios',
  dc2:'Número de órdenes asignados del día：',
  dc3:'Tasa de reembolso del día：',
  dc4:'Tasa de reembolso de nuevos órdenes del día：',
  dc5:'Tasa de reembolso del día anterior：',
  dc6:'Tasa de reembolso del mes：',
  dc7:'Number of repayments del día：',
  dc8:'Númber of órdenes asignados del día anterior：',
  dc9:'Tasa de reembolso de nuevos órdenes del mes：',
  dc10:'Órdenes pendientes para reembolsar：',
  dc11:'Número de reembolsos del día anterior：',
  dc12:'Nombre de empleado',
  dc22:'Número de órdenes asignados del día',
  dc13:'Órdenes pendientes actual',
  dc14:'Número de reembolsos del día',
  dc15:'Tasa de reembolso de nuevos órdenes del día',
  dc16:'Monto de órdenes pendientes',
  dc17:'Tasa de reembolso del mes',
  dc18:'Tasa de reembolso de nuevos órdenes del mes',
  dc19:'Númber of órdenes asignados del día anterior',
  dc20:'Número de reembolsos del día anterior',
  dc21:'Tasa de reembolso del día anterior',

  der1:'Configuración del período de cuenta',
  der2:'Condonación de cobranza',
  der3:'Añadir condonación',
  der4:'Editar condonación',
  der5:'Porcentaje de máxima condonación de cada órden',
  der6:'Número máximo de órdenes con condonación del mes',
  der7:'Después de 7 días estimación',
  der8:'Número estimado de casos',

  call0:'Volver a los informes de datos en tiempo real',
  call1:'Reembolsado',
  call2:'Fecha de cobra：',
  call3:'Todo',
  call4:'Número de nuevos órdenes del día：',
  call5:'Número de órdenes de clientes de préstamo nuevo：',
  call6:'Número de órdenes de clientes de préstamo consecutivo：',
  call7:'Número de reembolsos de clientes de préstamo nuevo acutal：',
  call8:'Número de reembolsos de clientes de préstamo consecutivo acutal：',
  call9:'Tasa de reembolso de clientes de préstamo nuevo actual：',
  call10:'Tasa de reembolso de clientes de préstamo consecutivo actual：',
  call11:'Tasa de reembolso total actual：',

  loan1:'Página de datos general post-préstamo',
  loan2:'Fecha de préstamo',
  loan3:'Número de préstamos',
  loan7:'Apresurar el caso',

  oter:'Número de reembolsos',
  oter1:'Lista de órdenes fallidas de préstamo',
  oter2:'refinanciar',
  oter3:'Préstamo con éxito',

  txl1:'familia',
  txl2:'vulgaridad',

  loan4:'El primer crédito',
  loan5:'Tras los créditos',

  qux:'abrogaci',
  cffkqr:'Préstamo recurrente, por favor confirme la información',
  fkje1:'Importe del préstamo：',
  orderNo1:'La Orden No： ',
  phone1:'teléfono： ',
  fkz1:'Préstamo en curso...',

  cd1:'Ver los detalles',
  cd2:'Detalles de las estadísticas de la historia in urge',
  callnum:'Número de colecciones',
  pingt:'plataforma',
  iscancelar:'Cerrar o no el pedido',
}
export default es;