<template>
    <div class="leftbar">
            <el-menu
            :default-active="activemenu"
            style="border-right:none;"
            class="el-menu-vertical-demo"
            router
            @open="handleOpen"
            @close="handleClose"
            background-color="#545c64"
            text-color="#fff"
            :collapse="changmenu"
            active-text-color="#ffd04b">
                <template v-for="(item,index) in menuList" >
                    <el-submenu :index=index.toString() :key="index">
                        <template slot="title">
                            <i v-if="item.meta.icon!=''" :class="item.meta.icon"></i>
                            <span :title="item.name">{{item.name}}</span>
                        </template>
                        <el-menu-item v-for="(sonitem,sonindex) in item.children" :index="sonitem.component" :key="sonindex">
                            <!-- <span v-if="sonitem.component=='/anchor/playapply' && minimumMoney>0">
                                <el-badge :value="minimumMoney" :max="99" class="item">
                                    {{sonitem.meta.title}}
                                </el-badge>
                            </span>
                            <span v-else-if="sonitem.component=='/anchor/examine' && minimumAudit>0">
                                <el-badge :value="minimumAudit" :max="99" class="item">
                                    {{sonitem.meta.title}}
                                </el-badge>
                            </span>
                            <span v-else>
                                {{sonitem.meta.title}}
                            </span> -->
                            <span :title="sonitem.meta.title">{{sonitem.meta.title}}</span>
                        </el-menu-item>
                    </el-submenu>
                </template>
            </el-menu>
    </div>
</template>
<script>
import apiurl from "@/api/sys"
import _api from "@/api/index"
export default {
    data(){
        return{
            url:"/rolePer/getPermission",
            isCollapse:false,
            menuList:[],
            minimumMoney:0,
            minimumAudit:0,
            rulesList:[],
            activemenu:"/home"
        }
    },
    created(){
        this.menuList=JSON.parse(localStorage.getItem("menu"))
        // console.log(this.menuList)
        this.getNum()
    },
    props:{
        changmenu:{
            type:Boolean,
            default:false
        },
        logourl:{
            type:String
        },
        activePath: {
            type: String,
            default: ''
        }
    },
    methods:{
        handleOpen(){},
        handleClose(){},
        getNum(){
            _api.get(apiurl.audits,{}).then(res=>{
                if(res.code==200){
                    this.minimumAudit=res.data.minimumAudit
                    this.minimumMoney=res.data.minimumMoney
                }
            })
        }
    },
    watch:{
    $route(to,from){
        this.activemenu=to.path
            if(to.path!="/error/500"){
                this.getNum()
            }
        }
    }
}
</script>
<style>
.el-badge__content{border:none!important;}
.el-badge__content.is-fixed{top: 11px!important;right: 3px!important;}
</style>
<style scoped>
.leftbar{ background: #0f080f; height: 100%;}
</style>
